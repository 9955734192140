import React from 'react';
import { ActivityBookingProps } from './types';
import * as Styled from './styles';
import Heading from '../../molecules/Heading';
import ActivityBookingForm from '../../organisms/Form/ActivityBooking';

const ActivityBooking: React.FC<ActivityBookingProps> = ({ data }) => {
    const {
        heading: { title, subtitle, description },
        emailSubject: emailSubject,
        price: price
    } = data;

    return (
        <Styled.ActivityBooking>
            <Styled.ActivityBookingHeading>
                <Heading {...{ title, subtitle, content:description }}/>
            </Styled.ActivityBookingHeading>          
            <ActivityBookingForm {...{ emailSubject, price }}/>
        </Styled.ActivityBooking>
    )
}

export default ActivityBooking;