import { graphql } from 'gatsby';
import React from 'react';

import ActivityBooking from '../../ActivityBooking';
import { ActivityBookingSectionProps } from './types';

const ActivityBookingSection: React.VFC<ActivityBookingSectionProps> = ({ title, subtitle, description, emailSubject, price }) => {
    return (
        <ActivityBooking
            data={{
                heading: {
                    title: title ?? '',
                    subtitle: subtitle ?? '',
                    description: description ?? ''
                },
                emailSubject: emailSubject ?? '',
                price: price ?? ''
                
            }}
        />
    )
}

export const fragment = graphql`
    fragment ActivityBookingSectionFragment on SanityActivityBookingSection {
        _key
        _type
        title
        subtitle
        description
        emailSubject
        price
        backgroundColor
        gutterTop
        gutterBottom
        isFullWidth
    }
`;

export default ActivityBookingSection;