import { ActivityBookingFormMessagesProps } from './types';

const messages: ActivityBookingFormMessagesProps = {
  common: {
    required: 'Required',
  },
  email: {
    name: 'email',
    placeholder: 'Your email',
    invalid: 'Please correct your email address',
    label: 'Email'
  },
  customerName: {
    name: 'customerName',
    placeholder: 'Your name (same as on your reservation)' 
  },
  message: {
    name: 'message',
    placeholder: 'Write any additional notes here (optional)',
  },
  privacy: {
    name: 'privacy',
    invalid: 'Terms of service and privacy policy must be accepted',
    label: 'I accept Lynge North\'s terms and conditions and privacy policy',
  },
  button: {
    label: 'SIGN ME UP!',
  },
  submit: {
    success: 'The message was successfully sent!',
    invalid: 'Sending the message has failed!',
  },
};

export default messages;
