import { FormikConfig } from 'formik';

import { FormResponse } from './enums';
import { ActivityBookingFormValuesProps } from './types';
const URL_VALUE = '/api/sendgrid-activity';

const useOnSubmit = (emailSubject: string) => {
    const onSubmit: FormikConfig<ActivityBookingFormValuesProps>['onSubmit'] = async (
        data,
        { resetForm, setStatus }
    ) => {
        try {
            const {customerName, email, message} = data;

            const payLoad = {
              customerName: customerName,
              email: email,
              message: message,
              emailSubject: emailSubject
            };

            const response = await fetch(URL_VALUE, {
              method: `POST`,
              body: JSON.stringify(payLoad),
              headers: {
                'content-type': `application/json`,
              },
            });
      
            if (!response.ok) {
              return setStatus(FormResponse.Fail);
            }
      
            resetForm();
      
            setTimeout(() => {
              setStatus(undefined);
            }, 4000);
      
            setStatus(FormResponse.Success);
          } catch {
            setStatus(FormResponse.Fail);
          }
    }
    
    return { onSubmit };
}

export default useOnSubmit;