import { string } from 'prop-types';
import * as Yup from 'yup';

import messages from './messages';
import { ActivityBookingFormValuesProps } from './types';

export const initialValues: ActivityBookingFormValuesProps = { 
  email: '', 
  message: '', 
  privacy: false , 
  customerName: ''

};

export const validationSchema = Yup.object().shape({
  email: Yup.string().required(messages.common.required).email(messages.email.invalid),
  customerName: Yup.string().required(messages.common.required).min(3, "Your name must be at least 3 characters long"),
  privacy: Yup.bool().required(messages.common.required).oneOf([true], messages.privacy.invalid),
});
